.op-quick-add-menu
  $background: var(--button--alt-highlight-background-color)
  $background-hover: var(--button--alt-highlight-background-hover-color)
  $color: var(--button--alt-highlight-font-color)
  flex-shrink: 0

  &--button
    > .spot-icon::before
      display: flex
      justify-content: center
      align-items: center
      width: 2rem
      height: 2rem
      border-radius: 50%
      @include button-style($background, $background-hover, $color)

    &:hover
      > .spot-icon::before
        background: var(--button--alt-highlight-background-hover-color)
